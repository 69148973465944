/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer" v-for="i in controls" :key="i">{{ i }}</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="px-4">
              <div class="vx-row">
                <div class="vx-col w-2/3 flex items-center pl-0">
                  <h2 class="font-bold">Security Threat</h2>
                </div>
                <div class="vx-col w-1/3 flex items-center">
                  <h2 class="font-bold">CIA</h2>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Theft of customer information</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Unauthorized modification of internal batch process</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Accidental disclosure of departmental information to ex-employees</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Failure of data encryption controls on TLS gateways</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Failure of secondary data center</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input4">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Sustained DDOS attack on Internet facing services</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input5">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 my-6">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Modification and publication of policy documents without adequate management signoff</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input6">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row main-border bg-light rounded-lg p-4 mt-6 mb-1">
                <div class="vx-col w-2/3 flex items-center">
                  <h4 class="font-semibold">Undisclosed sharing of corporate information between third-party suppliers</h4>
                </div>
                <div class="vx-col w-1/3">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input7">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      controls: [
        'Availability',
        'Availability',
        'Integrity',
        'Integrity',
        'Confidentiality',
        'Confidentiality',
        'Confidentiality',
        'Confidentiality',
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Confidentiality') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Integrity') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Confidentiality') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Confidentiality') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'Availability') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === 'Availability') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input6').textContent === 'Integrity') {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input7').textContent === 'Confidentiality') {
        document.getElementById('input7').style.borderColor = '#28C76F';
        document.getElementById('input7').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
  min-height: unset !important;
}
</style>
